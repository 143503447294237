import React, { useState, memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { KcContext } from '../../kcContext';
import Button from '../../components/Button';
import Input from '../../components/Input';
import styles from './LoginUpdateProfile.module.scss';
import messages from './messages';
import RegistrationOrLoginLink from '../../components/RegistrationOrLoginLink';
import Checkbox from '../../components/Checkbox';
import { TextContainer, Typography } from '@ryte/ui-components';
/* eslint-disable */

type KcLoginContext = Extract<
  KcContext,
  { pageId: 'login-update-profile.ftl' }
>;

const LoginUpdateProfile = memo(
  ({ kcContext }: { kcContext: KcLoginContext }) => {
    const { url, message, user } = kcContext;
    const { firstName, lastName, email, username } = user;
    const isError = message && message.type === 'error';
    const summaryMessage = message && message.summary;
    const intl = useIntl();
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
    const [isFirstNameEmpty, setIsFirstNameEmpty] = useState(
      !firstName || firstName === ''
    );
    const [isLastNameEmpty, setIsLastNameEmpty] = useState(
      !lastName || lastName === ''
    );

    const hasEmptyFirstName = isFirstNameEmpty && !isLastNameEmpty;
    const hasEmptyLastName = !isFirstNameEmpty && isLastNameEmpty;
    const hasEmptyName = isFirstNameEmpty && isLastNameEmpty;
    const hasEmptyField = isFirstNameEmpty || isLastNameEmpty;
    const hasKeycloakError = isError && !isFirstNameEmpty && !isLastNameEmpty;

    const firstNameOnChange = (event: React.ChangeEvent<HTMLInputElement>) =>
      setIsFirstNameEmpty(event.target.value.trim() === '');

    const lastNameOnChange = (event: React.ChangeEvent<HTMLInputElement>) =>
      setIsLastNameEmpty(event.target.value.trim() === '');

    useEffect(() => {
      setIsSubmitButtonDisabled(hasEmptyField);
    }, [isFirstNameEmpty, isLastNameEmpty]);

    return (
      <div className={styles.socialProviderContainer}>
        <div className={styles.formWrapper}>
          <TextContainer className={styles.titleContainer}>
            <Typography variant={'heading-1-extra'}>
              {intl.formatMessage(messages.social_provider_title)}
            </Typography>
            <Typography variant={'text-regular'}>
              {intl.formatMessage(messages.social_provider_description)}
            </Typography>
          </TextContainer>
          <div id="kc-form" className={styles.loginForm}>
            <div id="kc-form-wrapper">
              <form
                id="kc-update-profile-form"
                action={url.loginAction}
                method="post">
                <input
                  value={username || ''}
                  type="hidden"
                  id="username"
                  name="username"
                />
                <div className={styles.email}>
                  <Input
                    defaultValue={email || ''}
                    icon={'mail'}
                    disabled={true}
                    className={styles.input}
                  />
                </div>
                <input
                  value={email || ''}
                  type="hidden"
                  id="email"
                  name="email"
                />
                <Input
                  placeholder={intl.formatMessage(messages.first_name)}
                  tabIndex={1}
                  icon={'user'}
                  id="firstName"
                  name="firstName"
                  style="top"
                  defaultValue={firstName || ''}
                  {...((isFirstNameEmpty || hasKeycloakError) && {
                    autoFocus: true,
                  })}
                  error={isFirstNameEmpty || hasKeycloakError}
                  className={cn(
                    styles.input,
                    hasEmptyLastName ? styles.bottomInputError : '',
                    hasEmptyName || hasKeycloakError ? styles.inputErrors : ''
                  )}
                  onChange={firstNameOnChange}
                />
                <Input
                  placeholder={intl.formatMessage(messages.last_name)}
                  tabIndex={2}
                  icon={'user'}
                  id="lastName"
                  name="lastName"
                  style="bottom"
                  defaultValue={lastName || ''}
                  {...(hasEmptyLastName && {
                    autoFocus: true,
                  })}
                  error={isLastNameEmpty || hasKeycloakError}
                  className={cn(
                    styles.input,
                    hasEmptyFirstName ? styles.topInputError : ''
                  )}
                  onChange={lastNameOnChange}
                />
                {(hasEmptyField || hasKeycloakError) && (
                  <div className={styles.errorMessage}>
                    <Typography variant={'text-regular'} textColor={'negative'}>
                      {hasEmptyField
                        ? intl.formatMessage(messages.error_message)
                        : summaryMessage}
                    </Typography>
                  </div>
                )}
                <div></div>
                <Checkbox
                  id="user.attributes.emailConsent"
                  label={intl.formatMessage(messages.news_and_offers)}
                />
                <input
                  type="checkbox"
                  id="rememberMe"
                  name="rememberMe"
                  hidden
                  checked
                />
                <div id="kc-form-buttons" className={styles.buttons}>
                  <Button
                    text={intl.formatMessage(messages.log_in)}
                    disabled={isSubmitButtonDisabled}
                  />
                </div>
              </form>
            </div>
            <RegistrationOrLoginLink status={'login'} />
          </div>
        </div>
      </div>
    );
  }
);

export default LoginUpdateProfile;
