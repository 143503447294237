import { defineMessages } from 'react-intl';

export default defineMessages({
  email: {
    id: 'keycloakify.login.email',
    defaultMessage: 'Email Address',
  },
  password: {
    id: 'keycloakify.login.password',
    defaultMessage: 'Password',
  },
  forgot_password: {
    id: 'keycloakify.login.forgot_password',
    defaultMessage: 'Forgot Password?',
  },
  log_in: {
    id: 'keycloakify.login.log_in',
    defaultMessage: 'Log in',
  },
  title: {
    id: 'keycloakify.login.title',
    defaultMessage: 'Log in to your Account',
  },
  reAuthenticate: {
    id: 'keycloakify.login.reAuthenticate',
    defaultMessage: 'Please re-authenticate to continue',
  },
  password_reset_success_title: {
    id: 'keycloakify.login.password_reset_success_title',
    defaultMessage: 'Thank you!',
  },
  password_reset_success_text: {
    id: 'keycloakify.login.password_reset_success_text',
    defaultMessage:
      "We've sent password reset instructions to your email address. If no email is received within ten minutes, check that the submitted address is correct.",
  },
  back_to_login: {
    id: 'keycloakify.login.back_to_login',
    defaultMessage: 'Back to login',
  },
  or: {
    id: 'keycloakify.login.or',
    defaultMessage: 'or',
  },
  error_message: {
    id: 'keycloakify.login.error_message',
    defaultMessage: 'Please enter your credentials',
  },
});
