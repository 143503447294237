import React, { useState, memo, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useConstCallback } from 'powerhooks/useConstCallback';
import cn from 'classnames';

import { KcContext } from '../../kcContext';
import RegistrationOrLoginLink from '../../components/RegistrationOrLoginLink';
import Button from '../../components/Button';
import Input from '../../components/Input';
import styles from './Login.module.scss';
import messages from './messages';
import SocialButton from '../../components/SocialLoginButton';
import { Typography, TextContainer, Link } from '@ryte/ui-components';

// type usernameHidden is missing in KcContext in our keycloakify version but its value is probably added at runtime. The type is added in 7.15.11-rc.0.
type KcLoginContext = Extract<KcContext, { pageId: 'login.ftl' }> & {
  usernameHidden?: boolean;
};

const PasswordResetSuccess = ({ redirectUrl }: { redirectUrl: string }) => {
  const intl = useIntl();
  return (
    <div className={styles.passwordResetSuccess}>
      <TextContainer align={'center'} className={styles.headerContainer}>
        <Typography variant={'heading-1'}>
          {intl.formatMessage(messages.password_reset_success_title)}
        </Typography>
        <Typography variant={'text-regular'}>
          {intl.formatMessage(messages.password_reset_success_text)}
        </Typography>
      </TextContainer>
      <Button
        text={intl.formatMessage(messages.back_to_login)}
        onClick={() => (window.location.href = redirectUrl)}
      />
      <RegistrationOrLoginLink status={'login'} />
    </div>
  );
};

const Login = memo(({ kcContext }: { kcContext: KcLoginContext }) => {
  const {
    social,
    realm,
    url,
    usernameEditDisabled,
    login,
    auth,
    message,
    usernameHidden,
  } = kcContext;

  const intl = useIntl();

  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(true);
  const [isUsernameEmpty, setIsUsernameEmpty] = useState(true);
  const [isPasswordEmpty, setIsPasswordEmpty] = useState(true);
  const [isUsernameTouched, setIsUsernameTouched] = useState(false);
  const [isPasswordTouched, setIsPasswordTouched] = useState(false);
  const isError = message?.type === 'error';
  const summaryMessage = message?.summary;
  const isPasswordResetSuccess = message?.type === 'success';
  const doesSocialProviderExist =
    realm.password && social?.providers !== undefined;

  const hasUsernameError = isUsernameTouched && isUsernameEmpty;
  const hasPasswordError = isPasswordTouched && isPasswordEmpty;
  const hasKeycloakError = isError && !hasUsernameError && !hasPasswordError;

  const hasEmptyUsername = hasUsernameError && !hasPasswordError;
  const hasEmptyPassword = !hasUsernameError && hasPasswordError;
  const hasEmptyCredentials = hasUsernameError && hasPasswordError;
  const hasEmptyField = isUsernameEmpty || isPasswordEmpty;

  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const validatePrefilledData = () => {
    const usernameInput = usernameRef.current;
    const passwordInput = passwordRef.current;

    if (usernameInput?.value) {
      setIsUsernameTouched(true);
      setIsUsernameEmpty(false);
    }

    if (passwordInput?.value) {
      setIsPasswordTouched(true);
      setIsPasswordEmpty(false);
    }
  };

  const emailInputsHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isUsernameTouched) setIsUsernameTouched(true);
    setIsUsernameEmpty(event.target.value.trim() === '');
  };

  const passwordInputsHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!isPasswordTouched) setIsPasswordTouched(true);
    setIsPasswordEmpty(event.target.value.trim() === '');
  };

  useEffect(() => {
    setIsLoginButtonDisabled(hasEmptyField);
  }, [hasEmptyField]);

  useEffect(() => {
    validatePrefilledData();
  }, []);

  const onSubmit = useConstCallback(() => {
    setIsLoginButtonDisabled(true);
    return true;
  });

  const shouldDisableSubmitButton = !usernameHidden
    ? isLoginButtonDisabled
    : isPasswordEmpty;

  const socialLoginHandler = (loginUrl: string) => {
    window.location.replace(loginUrl);
  };

  const title = !usernameHidden
    ? intl.formatMessage(messages.title)
    : intl.formatMessage(messages.reAuthenticate);

  return (
    <div className={styles.container}>
      {isPasswordResetSuccess ? (
        <PasswordResetSuccess redirectUrl={url.loginUrl} />
      ) : (
        <div
          className={cn(styles.formWrapper, {
            [styles.withSocialProvider]: doesSocialProviderExist,
          })}>
          <Typography variant={'heading-1-extra'}>{title}</Typography>
          <div id="kc-form" className={styles.loginForm}>
            <div id="kc-form-wrapper">
              {doesSocialProviderExist && (
                <>
                  <div id="kc-social-providers">
                    {doesSocialProviderExist &&
                      social.providers &&
                      social.providers.map(provider => (
                        <SocialButton
                          key={provider.providerId}
                          socialProvider={provider}
                          onClick={() => socialLoginHandler(provider.loginUrl)}
                        />
                      ))}
                  </div>
                  <div className={styles.socialProviderWrapper}>
                    <div className={styles.socialProviderSeparator}>
                      <hr />
                      <span>{intl.formatMessage(messages.or)}</span>
                      <hr />
                    </div>
                  </div>
                </>
              )}
              {realm.password && (
                <form
                  id="kc-form-login"
                  onSubmit={onSubmit}
                  action={url.loginAction}
                  method="post">
                  {!usernameHidden ? (
                    <Input
                      onChange={emailInputsHandler}
                      placeholder={intl.formatMessage(messages.email)}
                      tabIndex={1}
                      icon={'mail'}
                      id="username"
                      style="top"
                      name="username"
                      defaultValue={login.username ?? ''}
                      type="email"
                      {...(usernameEditDisabled
                        ? { disabled: true }
                        : { autoComplete: 'off' })}
                      {...((isUsernameEmpty || hasKeycloakError) && {
                        autoFocus: true,
                      })}
                      error={hasUsernameError || hasKeycloakError}
                      className={cn(
                        hasEmptyPassword ? styles.bottomInputError : '',
                        hasEmptyCredentials || hasKeycloakError
                          ? styles.inputErrors
                          : ''
                      )}
                      reference={usernameRef}
                    />
                  ) : null}
                  <Input
                    onChange={passwordInputsHandler}
                    tabIndex={2}
                    placeholder={intl.formatMessage(messages.password)}
                    id="password"
                    icon={'lock'}
                    style={!usernameHidden ? 'bottom' : undefined}
                    name="password"
                    type="password"
                    autoComplete="off"
                    {...(hasEmptyPassword && {
                      autoFocus: true,
                    })}
                    error={hasPasswordError || hasKeycloakError}
                    className={cn(hasEmptyUsername ? styles.topInputError : '')}
                    reference={passwordRef}
                  />
                  <div>
                    {(hasUsernameError ||
                      hasPasswordError ||
                      hasKeycloakError) && (
                      <div className={styles.errorMessage}>
                        <Typography
                          variant={'text-regular'}
                          textColor={'negative'}>
                          {hasKeycloakError
                            ? summaryMessage
                            : intl.formatMessage(messages.error_message)}
                        </Typography>
                      </div>
                    )}
                    <div
                      className={cn(styles.forgotPassword, {
                        [styles.errorMessageExist]: isError,
                      })}>
                      {realm.resetPasswordAllowed && (
                        <Link href={url.loginResetCredentialsUrl}>
                          {intl.formatMessage(messages.forgot_password)}
                        </Link>
                      )}
                    </div>
                  </div>
                  <div id="kc-form-buttons" className={styles.buttons}>
                    <input
                      type="checkbox"
                      id="rememberMe"
                      name="rememberMe"
                      hidden
                      checked
                    />
                    <input
                      type="hidden"
                      id="id-hidden-input"
                      name="credentialId"
                      {...(auth?.selectedCredential !== undefined
                        ? { value: auth.selectedCredential }
                        : {})}
                    />
                    <Button
                      text={intl.formatMessage(messages.log_in)}
                      disabled={shouldDisableSubmitButton}
                    />
                  </div>
                </form>
              )}
            </div>
            {!usernameHidden && (
              <RegistrationOrLoginLink status={'registration'} />
            )}
          </div>
        </div>
      )}
    </div>
  );
});

export default Login;
